.App {
  text-align: center;
}

.App-logo {
  max-height: 40px;
  float: left;
  pointer-events: none;
  margin-right: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  max-height: 100px;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: top;
  color: white;
  padding: 2px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

H1 {
  margin: 2px
}
.App .App-header a {
  text-decoration: none;
}
.App .App-header a h1 {
  color: white;
  text-decoration: none;
}

.img {
  max-width: 100vw;
  margin-left: 4px;
  margin-right: 4px;
}

.img img {
  max-width: 1024px;
  width: 100%;
}

main {
  padding-bottom: 80px;
}
footer {
  bottom: 0px;
  height: 80px;
  left: 0;
  right: 0;
  background-color: #282c34;
  color: #DDDDDD;
  font-size: 10px;
  position: fixed;
  overflow-y: auto;
}
footer p {
  margin: 0;
  padding: 0;
  text-align: center;
}
